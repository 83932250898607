export default {
  EAT_CELL: 'fish and pearl',
  EAT_LOW_ICONS: 'low_icons',
  EST_SCATTER: 'scatter',
  EST_VINES: 'vines',
  EST_WILD: 'wild',
  EAT_SYMBOLS: 'symbols',
  EAT_BOXERS: 'boxers',
  EAT_POPUPS: 'popups',
  EAT_COINS: 'coins',
  EAT_BACKGROUND:'background',
  EAT_BONUS_BUTTON: 'bonus-button',
  EAT_BONUS_GAME_MULTIPLIERS: 'bonus-game-multipliers',
  EAT_BONUS_GAME_BOXERS: 'bonus-game-boxers',
  EAT_REFEREE: 'referee',
  EAT_BONUS_PURCHASE: 'bonus_purchase'
};
