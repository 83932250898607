import './import';
import '../scss/styles.scss';
import '../css/index.css';
import '../css/fonts.css';
import 'pixi-spine';
import './utils/flashlibExtantion';
// import './libs/fullscreen/fullscreen';
import './utils/number';
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import reducer from './redux/reducers';
import StoreObserver from './redux/storeObserver';
import Game from './controllers/controllerGame';

import OPWrapperService from './libs/op-wrapper-service';
import actionsModal from './redux/modals/actions';
import eModalType from './controllers/popups/eModalType';
import en from '../assets/i18n/en.json';
import enSocial from '../assets/i18n/en-social.json';

const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composer = devtools ? devtools : compose;

// export const store = createStore(reducer, composer(applyMiddleware(logger)));
export const store = createStore(reducer);

export const storeObserver = new StoreObserver(store);

const config = {
  buttons: [
    {
      type: 'settings',
      onClick: () => {
        store.dispatch(actionsModal.showModal({ type: eModalType.EMT_SETTINGS }));
      },
      styles: ['settings']
    },
  ],
  bundle: 'luckypunchbitstarz',
  localizationBundle: 'luckypunch',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
new OPWrapperService(config);

export const game = new Game();
