import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';
import { requestConfig } from '../api/rest';
import WebFont from 'webfontloader';
import AnimationsLoader from './animations/animationsLoader';
import ControllerStartScreen from './controllerStartScreen';
import ControllerTextField from './textField/ControllerTextField';
import SoundsPreloader from '../controllers/sounds/SoundsPreloader';
import SoundManager from './sounds/SoundManager';
import EMAdapter from '../platforms/everyMatrix/adapter';
import assets from '../../assets/flashlib/exported/AssetsCombined.json';
import meta from '../../assets/flashlib/exported/FlashLib.json';
import mobileAssets from '../../assets/mobileFlashlib/exported/AssetsCombined.json';
import mobileMeta from '../../assets/mobileFlashlib/exported/FlashLib.json';
import starsParticle from '../../assets/images/star_particle_fred_small.png';
import redGlow from '../../assets/images/red_glow.png';
import Config from './Config';
import preloaderBitstarzLogo from '../../assets/images/preloaderBitstarzLogo.png';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;

    this.config = {
      width: 2340,
      height: 1080,
    };

    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();
    EMAdapter.init();
  }

  start() {
    this.container = document.getElementById('container');
    OPPreloader.setSecondLogo(preloaderBitstarzLogo);
    this.gameSize = {
      mobileLandscape: {
        width: 2340,
        height: 1080
      },
      mobilePortrait: {
        width: 1080,
        height: 2340
      },
      desktop: {
        width: 2340,
        height: 1080
      },
    };
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      // transparent: true,
      resolution: 1,
      // autoResize: true,
    };
    this.app = new PIXI.Application(this.applicationOptions);

    this.scaleData = {
      app: this.app,
      // scaleRatio: 2,
      gameSize: this.gameSize
    };

    FlashLib.TextField = ControllerTextField;

    this.app.view.id = 'gameCanvas';
    this.container.appendChild(this.app.view);

    SoundsPreloader.startLoading();
    this.loadAssets();
    this.loadFonts();

    AnimationsLoader.startLoading(this.app, () => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    });

    requestConfig()
      .then(() => {
        console.log('Config received');
        this.configReceived = true;
        this.tryInitGame();
      });
  }

  loadAssets() {
    const flashLibAssets = PIXI.utils.isMobile.any ? mobileAssets : assets;
    const flashLib = PIXI.utils.isMobile.any ? mobileMeta : meta;

    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLibAssets', flashLibAssets, 'json');
    PIXI.Loader.shared.add('FlashLib', flashLib, 'json');
    PIXI.Loader.shared.add('starsParticle', starsParticle, 'json');
    PIXI.Loader.shared.add('redGlow', redGlow, 'json');
    PIXI.Loader.shared.add('BoxerCountFont', 'assets/fonts/BoxerCountFont.xml', 'font');
    PIXI.Loader.shared.onComplete.add(
      () => {
        console.log('Assets loaded');
        this.assetsLoaded = true;
        this.tryInitGame();
      }
    );
    PIXI.Loader.shared.onError.add(this.onLoadingError.bind(this));

    PIXI.Loader.shared.onProgress.add((data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
    PIXI.Loader.shared.load();

  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: [
          'Deadpool Movie',
          'Ubuntu bold',
          'Ubuntu regular',
          'ObelixPro',
          'Myriad Pro',
          'Phosphate Pro',
          'Inter Extra Bold',
        ],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }

  tryInitGame() {
    if (
      this.fontsLoaded &&
      this.assetsLoaded &&
      this.configReceived &&
      this.animationsLoaded
    ) {
      window.OPPreloader.hide();
      this.initGame();
    }
  }

  initGame() {
    SoundManager.init();
    EMAdapter.loadCompleted();

    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    this.app.stage.addChild(main);
    this.mainContainer = main;
    new ControllerStartScreen(main);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.drawMask,
      this
    );
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }

  drawMask(data) {
    const background = this.mainContainer.getChildByName('background');
    const x = data.isMobile && data.isPortrait ? 630 : 0;
    const y = data.isMobile && data.isPortrait ? -630 : 0;
    if (!this.gameMask) {
      this.gameMask = new PIXI.Graphics();
    } else {
      this.gameMask.clear();
    }
    this.gameMask.beginFill(0xFF3300);
    this.gameMask.drawRect(
      x,
      y,
      data.gameWidth,
      data.gameHeight
    );
    // debugger
    this.gameMask.endFill();
    background.addChild(this.gameMask);
    this.mainContainer.mask = this.gameMask;
  }
}
